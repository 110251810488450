import React, { useEffect, useState } from 'react'
import { useLocation } from 'wouter';
import Spinner from './Spinner'

function ShowResult({ params }) {

   const { code } = params
   // eslint-disable-next-line no-unused-vars
   const [location, setLocation] = useLocation();
   const [loading, setLoading] = useState(false)
   const [valid, setValid] = useState(null)

   useEffect(()=>{

      const getData = async () =>{
         setLoading(true)

         const res = await fetch(
           `https://ck.fw-12365.com/ashx/SecurityQueryApi.ashx?Key=tc201805051108&FWCode=${code}&Lang=en-gb `
         );
         
         const {State} = await res.json();
         setLoading(false);
         setValid(State);
      }

      getData()

   },[code])

   // const RenderAnimatedSuccess = () => {
   //    return (
   //      <div className="f-modal-alert">
   //        <div className="f-modal-icon f-modal-success animate">
   //          <span className="f-modal-line f-modal-tip animateSuccessTip"></span>
   //          <span className="f-modal-line f-modal-long animateSuccessLong"></span>
   //          <div className="f-modal-placeholder"></div>
   //          <div className="f-modal-fix"></div>
   //        </div>
   //      </div>
   //    );
   // }

   // const RenderAnimatedAlert = () => {
   //    return (
   //      <div className="f-modal-alert">
   //        <div className="f-modal-icon f-modal-warning scaleWarning">
   //          <span className="f-modal-body pulseWarningIns"></span>
   //          <span className="f-modal-dot pulseWarningIns"></span>
   //        </div>
   //      </div>
   //    );
   // }

   // const RenderAnimatedError = () => {
   //    return(
   //         <div class="f-modal-alert">
   //        <div className="f-modal-icon f-modal-error animate">
   //          <span className="f-modal-x-mark">
   //            <span className="f-modal-line f-modal-left animateXLeft"></span>
   //            <span className="f-modal-line f-modal-right animateXRight"></span>
   //          </span>
   //          <div className="f-modal-placeholder"></div>
   //          <div className="f-modal-fix"></div>
   //        </div>
   //      </div>
   //    )
   // }

   const dictionary = {
     1: {
       img: "/check.png",
       width: "100px",
       message:
         "This item has been successfully authenticated and we're able to very it is genuine.",
     },
     2: {
       img: "/alert.png",
       width: "130px",
       message:
         "The code you entered is valid, but it has already been verified, this means that you have received, verified if this product is autehentic. If there are any concerns, please contact us",
       extra: "info@thevapemarketrd.com",
     },
     "-1": {
       img: "/fail.webp",
       width: "100px",
       message:
         "The code you entered is invalid and you should not use this product. To report the store in which you reserved, please fill out the information below",
     },
     0: {
       img: "/fail.webp",
       width: "100px",
       message:
         "The code you entered is invalid and you should not use this product. To report the store in which you reserved, please fill out the information below",
     },
     4: {
       img: "/fail.webp",
       width: "100px",
       message:
         "The code you entered is invalid and you should not use this product. To report the store in which you reserved, please fill out the information below",
     },
   };

   if (loading && !valid) return <Spinner/>

     return (
       <div className="fondo_result">
         <div className="container_result">
           <img className="logo" src="/logoverify2.png" alt="" />
           <div className="inner_content">
             <img
               className="img_result"
               style={{ width: dictionary[valid]?.width }}
               src={dictionary[valid]?.img}
               alt=""
             />
             <p className="result_message">
               {dictionary[valid]?.message}
               {valid === 2 && (
                 <a
                   className="link"
                   href={`mailto:${dictionary[valid]?.extra}`}
                 >
                   info@thevapemarketrd.com
                 </a>
               )}
             </p>
           </div>
           <button id="back" onClick={() => setLocation("/")}>
             Go back
           </button>
           <a
             href="http://thevapemarket.com"
             target="_blank"
             rel="noopener noreferrer"
           >
             <img style={{ width: "150px" }} src="/LOGO-01.png" alt="" />
           </a>
         </div>
       </div>
     );
}

export default ShowResult
import React from "react";
import { Switch, Route } from "wouter";
import Error404 from "./components/Error404";
import Home from "./components/Home";
import ShowResult from "./components/ShowResult";

function App() {
  return (
    <Switch>
      <Route path="/" component={Home} />
      <Route path="/securitycode/:code" component={(params) => ShowResult(params)} />
      <Route component={Error404} />
    </Switch>
  );
}

export default App;

import React from 'react'
import { useLocation } from 'wouter';

function Error404() {

   // eslint-disable-next-line no-unused-vars
   const [location, setLocation] = useLocation();

  return (
    <div className="container">
      <img
        style={{ width: "200px" }}
        src="/404.png"
        alt="error 404 not found."
      />
      <h1 style={{ fontSize: "3rem" }}>Error 404</h1>
      <p style={{ fontSize: "1.2rem" }}>
        Sorry, the page you're searching for doesn't exist.
      </p>
      <button id="back" onClick={() => setLocation("/")}>
        Go back home
      </button>
    </div>
  );
}

export default Error404
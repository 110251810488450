import React from 'react'

function spinner() {
  return (
   <div className="container">
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
   </div>
  );
}

export default spinner
import React, { useState } from 'react'
import { useLocation } from 'wouter';

function Home() {

   const code = window.location.search.replace("?code=", "");
   // eslint-disable-next-line no-unused-vars
   const [location, setLocation] = useLocation();
   const [value, setValue] = useState(code);
   const [error, setError] = useState(false);

   const handlerForm = async (e) => {
      e.preventDefault()
     
      !value ? setError(true) : setError(false);

      if (value) {
        setLocation(`/securitycode/${value}`);
      }
   }

  return (
    <div className='fondo'>
      <div className="container">
        <img className="logo" src="/logotvmverif.png" alt="" />
        <h2>Check your product authentic</h2>
        <form onSubmit={handlerForm}>
          <input
            type="text"
            value={value || ""}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Please enter the security code"
          />
          {error && (
            <span id="error">Empty field, please enter verification code.</span>
          )}
          <button>Check</button>
          <a
            href="http://thevapemarket.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img style={{ width: "150px" }} src="/logoFooter.png" alt="" />
          </a>
        </form>
      </div>
    </div>
  );
}

export default Home